<template>
  <div class="panel-sd">
    <div class="panel-tt">
      <!-- <img src="../../../assets/image/panel-nav.png" alt="" width="40"> -->
      <div class="tit">{{ title }}</div>
    </div>
    <!-- 新闻中心里面左边的公司新闻和通知公告框 -->
    <ul class="lanmu">
      <li
        v-for="(item, index) in navs"
        :key="index"
        :data-index="index"
        :class="[item.router === routePath ? `active open` : ``]"
      >
        <router-link :to="item.router">{{ item.title }}</router-link>

        <ul>
          <li
            v-for="(item, index) in item.child"
            :key="index"
            :data-index="index"
            :class="[item.router === routePath ? `active open` : ``]"
          >
            <router-link :to="item.router">{{ item.title }}</router-link>
          </li>
        </ul>
      </li>
    </ul>
    <img src="../../../assets/image/ztjy222.jpg" alt="">
  </div>
</template>


<script>
import { reactive, toRefs, getCurrentInstance } from "vue";

export default {
  name: "Panel",
  props: ["title", "navs"],
  setup(props) {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      routePath: proxy.$router.currentRoute.value.path,
    });
    return {
      ...toRefs(state),
      props,
    };
  },
};
</script>

<style scoped>
</style>
