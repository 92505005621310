<template>
  <div class="main clearfix">
    <!-- <div class="m_nav">
      <div class="m_nav_box">   
      </div>
    </div> -->
    <CurrentLocation />
    <div class="box">
      <div class="ct2-sd">
      <PanelNav :title="title" :navs="navs" />
      </div>
    <div class="ct3-mn">
      <PanelImageList :more="true" :data="data" />
    </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import CurrentLocation from "../layout/components/CurrentLocation";
import PanelNav from "../layout/components/PanelNav";
import PanelLast from "../layout/components/PanelLast";
import PanelImageList from "../layout/components/PanelImageList";

import axios from "@/api/apis";
import { getFile } from "@/api/http";
import { log } from "util";

export default {
  name: "Company",
  components: { CurrentLocation, PanelNav, PanelLast, PanelImageList },
  created() {},
  setup() {
    const state = reactive({
      title: "培训教育",
      navs: [
        // {
        //   title: "空中课堂",
        //   router: "",
        //   child: [
        //     {
        //       title: "渝北近视防控干预",
        //       router: "/train/airClass/airClassYbjsfkgykt",
        //       data: {
        //         list: []
        //       }
        //     }
        //   ],
        //   data: {
        //     list: []
        //   }
        // }
        {
              title: "渝北近视防控干预",
              router: "/train/airClass/airClassYbjsfkgykt",
              data: {
                list: []
              }
            }
      ],
      data: {
        currentPage: "1",
        size: "1",
        total: "1",
        list: []
      }
    });

    const loadTrian = () => {
      axios
        .post("https://cqgdrm.com/api/website/train/page", {
          group: 1,
          pageSize: 100,
          groupId: 1,
          groupChildId: 1
        })
        .then(function(data) {
          state.data.currentPage = data.currentPage;
          state.data.size = data.size;
          state.data.total = data.total;
          // var image=''
          //   if(item.images!= null){
          //   image=item.images[0]
          // }else{
          //   image=''
          // }

          data.data.forEach(item => {
            state.data.list.push({
              id: item.id,
              title: item.title,
              type: 2,
              abstracts:item.abstracts,
              content: item.content,
              // images:image,
              createdTime: item.createdTime,
              imageUrl: getFile() + item.imageUrl
            });
          });
        })
        .catch(function(error) {
          console.log(error);
        });
    };

    onMounted(() => {
      loadTrian();
    });
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style scoped>
.ct3-mn {
    /* margin-left: 200px; */
    display: block;
    float: right;
    width: 910px;
  }
</style>
