<template>
<div>
  <div v-if="!more" class="fengmian-tit">
    <router-link :to="router" class="more">更多</router-link>
    <span>{{ title }}</span>
  </div>
  <div class="list-3 bordernone">
    <ul class="imglist">
      <li
        v-for="(item, index) in data.list"
        :key="index"
        :data-index="index"
        :title="item.title"
      >
        <router-link
          v-if="item.type == 1"
          :to="{
            path: item.router,
            query: { id: item.id },
          }"
          :title="item.title"
        >
          <div class="content">
            <div class="img-wrap">
              <div class="img-box">
                <img
                  :src="item.imageUrl"
                />
              </div>
            </div>
            <div class="title">{{ item.title }}</div>
          </div>
        </router-link>

        <a v-else :href="item.content" target="_blank">
          <div class="content">
            <div class="img-wrap">
              <div class="img-box">
                <img
                  :src="item.imageUrl"
                />
              </div>
            </div>
            <div class="title">{{ item.title }}</div>
          </div>
        </a>
      </li>
    </ul>
  </div>
 <div v-if="more" class="pages">
    <ul>
      <li>
        <span class="pageinfo"
          >共 <strong>{{ data.currentPage }}</strong
          >页<strong>{{ data.total }}</strong
          >条记录</span>
      </li>
    </ul>
  </div>
  </div>
</template>

<script>
import { toRefs } from "vue";
import dateFormat from "@/utils/dateFormat";

export default {
  name: "PanelImageList",
  components: {},
  props: ["title", "router", "more", "data"],
  setup(props) {
    return { dateFormat, ...toRefs(props) };
  },
  methods: {}
};
</script>

<style scoped>
li {
  list-style: none;
  display: inline-block;
  width: 33.3%;
  /*三列图片排列*/
  text-align: center;
  /*内容居中*/
  overflow: hidden;
  /*超出隐藏*/
  vertical-align: bottom;
  /*内容底部对齐*/
}
.content {
  width: 100%;
  padding: 15px;
  overflow: hidden;
}
.img-box {
  height: 120px;
  overflow: hidden;
}
.img-box img {
  position: relative;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.title {
  padding-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
}
@media screen and (max-width: 767px) {
  li {
    list-style: none;
    display: inline-block;
    width: 100%;
    /*三列图片排列*/
    text-align: center;
    /*内容居中*/
    overflow: hidden;
    /*超出隐藏*/
    vertical-align: bottom;
    /*内容底部对齐*/
  }
}
</style>
